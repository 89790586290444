// All the user related helper functions are written here

import { useDispatch, useSelector } from "react-redux";
import http from "../../helpers/Interceptors"
import { GET_USER_DETAILS_API } from "../ApisPath";
import { changeURLLastString, deleteCookie } from "../../constants/commonFunc";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginSuccessAction, RedirectLoginAction } from "../../redux/actions/userActions";
import Paths from "../Paths";

const USER_HELPER = () => {

    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector(state => state?.user)
    const isUserLogin = !!user?.isLogin
    const loginUserInfo = useSelector(state => state?.user?.userInfo)


    // to make user logout from the application
    const makeUserLogout = () => {
        deleteCookie("Authorization")
        localStorage.removeItem("user")
        window.open("/", "_self")

    }

    // get user full info, no need to pass because its already passed in Interceptor.js
    const getUserDetails = () => {
        return http.get(GET_USER_DETAILS_API)
            .then((res) => {
                if (res?.status === 200) {
                    dispatch(LoginSuccessAction(res?.data?.data))
                }
                return res
            }).catch((err) => {
                return err
            })
    }

    const handleGoBack = (replace = false) => {
        navigate(-1, { replace: replace })
    }


    // common function to redirect into login screen if user is not logined
    const RedirectToLoginScreen = (redirectUrl = location?.pathname) => {
        if (!isUserLogin) {
            dispatch(RedirectLoginAction(redirectUrl));
            navigate(changeURLLastString(Paths.LoginPage, "mobile"));
        }
    }

    return { isUserLogin, loginUserInfo, getUserDetails, makeUserLogout, handleGoBack, RedirectToLoginScreen }
}

export default USER_HELPER
