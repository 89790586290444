import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Fragment, useEffect } from "react";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import { useLocation } from "react-router-dom"
import USER_HELPER from "./helpers/apis/userHelper";
import { getCookie } from "./constants/commonFunc";
import CARD_HELPER from "./helpers/apis/cardHelper";
import COLLECTION_HELPER from "./helpers/apis/collectionHelper";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

    const location = useLocation();
    const { isUserLogin, getUserDetails } = USER_HELPER() // all the user related functions written in user helper file
    const { getMyFavouriteCards } = CARD_HELPER() // all the card related info written in card helper
    const { getMyFavouriteCollection } = COLLECTION_HELPER() // all the collection related info written in collection helper file


    useEffect(() => {
        // checking cookie is present or not, if available get the user full information
        const isAuthCookie = getCookie("Authorization")
        if (!!isAuthCookie) {
            getUserDetails()
        }
    }, [])

    useEffect(() => {
        // scroll automatic on top when page routes changes
        window.scrollTo(0, 0);

    }, [location]);



    useEffect(() => {
        // checking if user is logged in, then we are fetching all the liked card and collection and user info
        if (isUserLogin) {
            getMyFavouriteCards()
            getMyFavouriteCollection()
            getUserDetails()
        }
        console.log("print env", process.env.REACT_APP_ENV)
    }, [isUserLogin])


    return (
        <Fragment>
            {isUserLogin && <PrivateRoutes />}
            <PublicRoutes />
            <ToastContainer />
        </Fragment>
    );
}

export default App;
