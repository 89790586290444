import { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import Paths from "./helpers/Paths";
import Collection from "./pages/CollectionPage/Collection";
import TokenDetail from "./pages/TokenDetailPage/TokenDetail";
import Login from "./pages/LoginFlowPage/Login";
import HomePage from "./pages/HomePage/Home";
import Tier from "./pages/TierPage/Tier";
import Marketplace from "./pages/MarketplacePage/Marketplace";
import TransactionHistory from "./pages/CollectionPage/TransactionHistory"
import ActivityHistory from "./pages/CollectionPage/ActivityHistory";
import OfferHistory from "./pages/TokenDetailPage/OfferHistory";
import MoreCollections from "./pages/HomePage/MoreCollections";
import UserProfileSetting from "./pages/SettingsPage/UserProfileSetting";

// all Public routes defined here - not logged in user case

const PublicRoutes = () => {
    return (
        <Fragment>
            <Routes>
                <Route path={Paths.MoreCollectionsPage} element={<MoreCollections />} />
                <Route path={Paths.UserProfileSettings} element={<UserProfileSetting />} />
                <Route path={Paths.MarketplacePage} element={<Marketplace />} />
                <Route path={Paths.TierPage} element={<Tier />} />
                <Route path={Paths.TokenDetailPage} element={<TokenDetail />} />
                <Route path={Paths.OfferHistoryPage} element={<OfferHistory />} />
                <Route path={Paths.CollectionPage} element={<Collection />} />
                <Route path={Paths.ActivityHistoryPage} element={<ActivityHistory />} />
                <Route path={Paths.TransactionHistoryPage} element={<TransactionHistory />} />
                <Route path={Paths.LoginPage} element={<Login />} />
                <Route path={Paths.HomePage} element={<HomePage />} />
            </Routes>
        </Fragment>
    );
};

export default PublicRoutes;