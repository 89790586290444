import React, { Fragment, useState } from "react";
import OtpInput from "react-otp-input";
import PrimaryButton from "./PrimaryButton";
import http from "../../helpers/Interceptors"
import { API_BASE_URL, OPERATOR_ID } from "../../constants/envConstants";
import { useDispatch, useSelector } from "react-redux";
import { LoginSuccessAction } from "../../redux/actions/userActions";
import { setCookie, validateEmail } from "../../constants/commonFunc";
import { useNavigate } from "react-router-dom";
import Paths from "../../helpers/Paths";
import { VERIFY_OTP_API } from "../../helpers/ApisPath";

const EnterOTPBlock = () => {


    const navigate = useNavigate()
    const mobileNo = useSelector(state => state?.user?.initiateLoginData?.phone)
    const [otp, setOtp] = useState("");
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState({ status: false, message: "" })
    const [successMessage, setSuccessMessage] = useState("")
    const redirectUrl = useSelector((state) => state?.user?.redirectAfterLogin)

    const handleMobileNumberSubmit = (mobileNumber) => {
        setErrorMessage({ status: false, message: "" })
        if (mobileNumber.length < 10) {
            setErrorMessage({ status: true, message: "Please enter valid number" })
            return
        }
        setLoading(true)
        const formData = {
            "loginType": 4,
            "data": mobileNumber
        }

        http.post(API_BASE_URL + "user/onboard/" + OPERATOR_ID, formData)
            .then(function (response) {
                if (response.status === 200) {
                    setSuccessMessage("OTP resent successfully")
                    setOtp("")

                }
            })
            .catch(function (error) {
                setErrorMessage({ status: true, message: "Failed to send OTP" + error?.response?.data?.message })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleOTPSubmit = () => {
        setErrorMessage({ status: false, message: "" })
        setSuccessMessage("")
        if (otp.length < 6) {
            setErrorMessage({ status: true, message: "Please enter otp code" })
            return
        }
        setLoading(true)
        const isValidEmail = validateEmail(mobileNo)
        const loginType = isValidEmail ? 1 : 2
        const formData = {
            "data": mobileNo,
            "verificationCode": parseInt(otp),
            "verificationCodeType": loginType
        }

        http.post(VERIFY_OTP_API, formData)
            .then((response) => {
                if (response.status === 200) {
                    if (response && response.data && !!response?.data?.data) {
                        const apiData = response.data.data
                        window.localStorage.setItem("user", JSON.stringify(apiData))
                        dispatch(LoginSuccessAction(apiData))
                        setCookie("Authorization", `Bearer ${apiData?.token}`, 30)
                        if (apiData.name || apiData.bio || apiData.profilePic) {
                            navigate(redirectUrl ? redirectUrl : Paths.HomePage, { replace: true })
                        } else {
                            navigate("/login/setup-profile", { replace: true })
                        }
                    }
                } else {
                    setErrorMessage({ status: true, message: "Incorrect OTP" })
                }
            })
            .catch((err) => {
                setErrorMessage({
                    status: true,
                    message: err?.response?.data?.message ? err?.response?.data?.message : "Failed to validate OTP"
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Fragment>
            <p className="font14px mt-2">
                An OTP has been sent to your registered email address{" "}
                <span className="lightmode-text-secondary">{mobileNo}</span>
            </p>

            <OtpInput
                value={otp}
                onChange={(otp) => setOtp(otp)}
                numInputs={6}
                className="otp-input-form"
                containerStyle="otp-grid"
                isInputNum
                placeholder="______"
            />
            <div className="d-flex justify-content-center align-items-center mt-15">
                <p className="font14px">Didn't receive it?</p>


                <button className="btn font14px lightmode-text-secondary p-1" type="button"
                    onClick={() => handleMobileNumberSubmit(mobileNo)}>
                    Resend OTP
                </button>
            </div>
            <div className={`primaryBtn-block mt-50`}>
                <PrimaryButton heading="Submit" onClick={handleOTPSubmit} type="button" isLoading={loading} />
            </div>
            {errorMessage.status &&
                <p className="form-error">
                    {errorMessage?.message}
                </p>
            }
            {successMessage &&
                <p className="form-success">
                    {successMessage}
                </p>
            }
        </Fragment>
    );
};

export default EnterOTPBlock;
