import React, { Fragment, useRef, useState } from "react";
import "../../components/Profile/ProfileStyles.css";
import { NewSortIcon, SettingIcon } from "../../helpers/svgConst";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { a11yProps, TabPanel } from "../../constants/commonFunc";
import Badge from "@mui/material/Badge";
import {
    GET_MY_BIDS_API,
    GET_MY_LISTED_CARDS_API,
    GET_MY_VAULT_CARDS_API,
    GET_MY_WISHLISTED_API,
    GET_SPECIAL_OFFER_BY_USER_FOR_CARD_API,
} from "../../helpers/ApisPath";
import UserProfileDetails from "../../components/Profile/UserProfileDetails";
import MyOwnListing from "../../components/Profile/OwnedListing";
import MyListedNFT from "../../components/Profile/MyListedNFT";
import MyWishListedNFT from "../../components/Profile/MyWishlistNFT";
import MyBidsNFT from "../../components/Profile/MyBidsNFT";
import { useSelector } from "react-redux";
import NewHamburgerMenu from "../../components/Common/NewHamburgerMenu";
import useOnClickOutside from "../../helpers/Hooks/useOnClickOutside";
import PackUnpackCards from "../../components/Profile/PackUnpackCards";
import Paths from "../../helpers/Paths";
import { useNavigate } from "react-router-dom";
import OwensHeader from "../../components/Common/OwensHeader";

const Profile = () => {
    const ownCount = useSelector((state) => state?.profile?.myOwnedData?.count);
    const listedCount = useSelector((state) => state?.profile?.myListData?.count);
    const wishlistCount = useSelector((state) => state?.profile?.myWishListData?.count);
    const myBidsCount = useSelector((state) => state?.profile?.myBidsData?.count);
    const myPacksCount = useSelector((state) => state?.profile?.myPacksData?.count)
    const ref = useRef(null)
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const [dropValue, setDropValue] = useState('listed')

    const handleToggleDropdown = () => {
        setIsOpen(!isOpen)
    }
    const handleSortMenuClickOutside = () => {
        setIsOpen(false);
    };
    const handleSelectDropdown = (value) => {
        setDropValue(value)
        handleSortMenuClickOutside()
    }

    const tabOptionsList = [
        { name: "Owned", value: "0", totalCount: ownCount },
        { name: "Listed", value: "1", totalCount: listedCount },
        { name: "Favourite", value: "2", totalCount: wishlistCount },
        { name: "My Bids", value: "3", totalCount: myBidsCount },
        { name: "Packs", value: "4", totalCount: myPacksCount },
    ];

    const [tabValue, setTabValue] = useState(
        parseInt(window.location.search.split("=")[1]) || 0
    );

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    useOnClickOutside(ref, handleSortMenuClickOutside);

    return (
        <Fragment>
            <section className="lightMode px-0">
                <div className="pi-15 pt-10 whiteBG">
                    <div className="d-flex pt-20">
                        <button className="ml-auto btn p-0 mr-2" onClick={() => navigate(Paths.UserProfileSettings)}>
                            <SettingIcon />
                        </button>

                        <OwensHeader />

                    </div>

                    <UserProfileDetails />
                </div>
                <div className="pl-15 pt-20 whiteBG">
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            className="profileTabFlex"
                            value={tabValue}
                            variant="scrollable"
                            onChange={handleChange}
                            TabIndicatorProps={{ style: { background: "#AD3430" } }}
                        >
                            {tabOptionsList.length > 0 &&
                                tabOptionsList.map((key, index) => {
                                    return (
                                        <Tab
                                            key={index}
                                            disableRipple
                                            label={
                                                <Badge
                                                    badgeContent={key?.totalCount}
                                                    className="styleBadge"
                                                >
                                                    {key?.name}
                                                </Badge>
                                            }
                                            className="font14px profileTab"
                                            {...a11yProps(index)}
                                        />
                                    );
                                })}
                        </Tabs>
                    </Box>
                </div>

                <div className="tabsblock">
                    <TabPanel value={tabValue} index={0}>
                        <MyOwnListing type={"OWNED"} apiPath={GET_MY_VAULT_CARDS_API} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <MyListedNFT type={"LISTED"} apiPath={GET_MY_LISTED_CARDS_API} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <MyWishListedNFT
                            type={"WISHLIST"}
                            apiPath={GET_MY_WISHLISTED_API}
                        />
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                        <div className="pos-rel d-flex w-100 justify-content-end" ref={ref}>
                            <button className='btn p-0 bidsAbsBtn' onClick={handleToggleDropdown} >
                                <NewSortIcon />
                            </button>
                            <div className={`${isOpen ? "py-3 px-4 tokenHeroAbsBtn right-10 top-40" : "d-none"}`}>
                                <button className={`btn d-block font14px  ${dropValue === "listed" ? "lightmode-text-marigold" : "lightmode-text-blue"}`} value="listed"
                                    onClick={() => handleSelectDropdown("listed")}>Listed</button>
                                <button className={`btn d-block font14px  ${dropValue === "unlisted" ? "lightmode-text-marigold" : "lightmode-text-blue"}`}
                                    value="unlisted"
                                    onClick={() => handleSelectDropdown("unlisted")}>Unisted</button>
                            </div>
                        </div>
                        {dropValue === "listed" &&
                            <MyBidsNFT type={"MY_BIDS"} apiPath={GET_MY_BIDS_API} />}
                        {dropValue === "unlisted" &&
                            <MyBidsNFT type={"MY_BIDS"} apiPath={GET_SPECIAL_OFFER_BY_USER_FOR_CARD_API} />}
                    </TabPanel>
                    <TabPanel value={tabValue} index={4}>
                        <PackUnpackCards type={"PACKS"} apiPath={GET_MY_VAULT_CARDS_API} />
                    </TabPanel>
                </div>
            </section>
        </Fragment>
    );
};

export default Profile;
