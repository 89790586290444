/* file is used for make and offer button and accept offer button
* and its also showing all the highest offer and selected offer will show
* using redux state */

import React, { Fragment, useEffect, useState } from 'react'
import { TimerIcon } from '../../helpers/svgConst'
import PrimaryButton from '../Login/PrimaryButton'
import { useDispatch, useSelector } from "react-redux";
import {
    API_FETCH_COMPLETE,
    API_FETCH_START,
    changeURLLastString,
    GET_ERROR_TOAST_MSG,
    PRICE_FORMAT,
    TIME_RENDERER
} from "../../constants/commonFunc";
import { HANDLE_ACCEPT_OFFER_API, HANDLE_ACCEPT_SPECIAL_OFFER_API } from "../../helpers/ApisPath";
import http from "../../helpers/Interceptors"
import Countdown from "react-countdown";
import { MakeAnOfferRedirectAction, SelectOfferAction } from "../../redux/actions/cardDetailActions";
import USER_HELPER from "../../helpers/apis/userHelper";
import Paths from "../../helpers/Paths";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const AcceptOfferBlock = ({ data, offerList }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const selectedOffer = useSelector((state) => state?.cardDetail?.selectedOffer)

    const [loading, setLoading] = useState(API_FETCH_COMPLETE)
    const [errorMessage, setErrorMessage] = useState({ state: false, message: "" })
    const { isUserLogin, loginUserInfo, RedirectToLoginScreen } = USER_HELPER();
    const userOffer = useSelector((state) => state?.cardDetail?.userOfferForCard)

    const handleMakeAnOffer = () => {
        if (!isUserLogin) {
            RedirectToLoginScreen()
        } else {
            dispatch(MakeAnOfferRedirectAction(data));
            if (data?.listingType) {
                navigate(changeURLLastString(Paths.ConfirmMakeAnOfferPage, data?.id));
            } else {
                //when listing type is not defined then it means we are trying to make offer for unlisted nft
                navigate(changeURLLastString(Paths.ConfirmMakeAnOfferPage, data?.id) + "?type=unlist");
            }

        }
    };


    const handleAcceptOffer = (type = "LISTED") => {
        setErrorMessage({ state: false, message: "" })
        if (Object.keys(selectedOffer).length === 0) {
            setErrorMessage({ state: true, message: "No offer selected" })
            return
        }
        setLoading(API_FETCH_START)
        let apiPath = ""
        if (type === "UNLISTED") {
            // when nft is unlisted and owner trying to accept the offer
            apiPath = changeURLLastString(HANDLE_ACCEPT_SPECIAL_OFFER_API, selectedOffer?.id)
        } else {
            // when nft is listed in marketplace and owner trying to accept the offer
            apiPath = changeURLLastString(HANDLE_ACCEPT_OFFER_API, selectedOffer?.id)
        }

        http.post(apiPath)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Offer Accepted");
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000)
                }
            }).catch((err) => {
                const msg = GET_ERROR_TOAST_MSG(err)
                toast.error("Failed to accept offer " + msg);
                setErrorMessage({ state: true, message: "Failed to accept offer " + msg })
            }).finally(() => {
                setLoading(API_FETCH_COMPLETE)
            })
    }


    useEffect(() => {
        if (!!selectedOffer && Object.keys(selectedOffer).length > 0 && selectedOffer.cardId === data?.id) return
        if (!data?.listingType) {
            //setting a highest offer for unlisted cards
            dispatch(SelectOfferAction(data?.highestSpecialOffer))
        } else {
            // setting a highest offer for listed cards
            dispatch(SelectOfferAction(data?.highestOffer))
        }
    }, [data])


    return (
        <Fragment>


            {(!!data?.expiryTime || !!selectedOffer?.price) &&
                <div className='main-white-card card-price-block mt-15'>
                    <div className='text-left'>
                        {!!data?.expiryTime &&
                            <Fragment>
                                <p className='font10px lightmode-text-gray'>Offer expires in</p>
                                <TimerIcon />
                                <p className='font16pxSemiBold gradient-Text d-inline ml-2'>
                                    <Countdown
                                        date={data?.expiryTime}
                                        zeroPadDays={1}
                                        renderer={TIME_RENDERER}
                                    />
                                </p>
                            </Fragment>
                        }
                    </div>
                    {!!selectedOffer?.price &&
                        <div className='text-right'>
                            <p className='font10px lightmode-text-gray'>Highest Price</p>
                            <p className='font16pxSemiBold gradient-Text'>$ {PRICE_FORMAT(selectedOffer?.price)}</p>
                        </div>
                    }
                </div>
            }

            {(data?.vaccount === loginUserInfo?.vaccount) &&
                <Fragment>
                    {/* WHEN NFT IS LISTED IN MARKETPLACE AND OWNER WANTED TO ACCEPT THAT OFFER*/}
                    {(data?.marketplaceId && !!offerList?.allOffers && data?.offerDetails?.totalOffers > 0) &&
                        <PrimaryButton heading="Accept Offer" primaryClassName="mt-15"
                            onClick={handleAcceptOffer}
                            isLoading={loading !== API_FETCH_COMPLETE} />
                    }

                    {(!!offerList?.allSpecialOffers && offerList?.allSpecialOffers.length > 0 && !data?.listingType) &&
                        <PrimaryButton heading="Accept Offer" primaryClassName="mt-15"
                            onClick={() => handleAcceptOffer("UNLISTED")}
                            isLoading={loading !== API_FETCH_COMPLETE} />
                    }
                </Fragment>
            }


            {(data?.vaccount !== loginUserInfo?.vaccount) && data?.listingType == 2 &&
                <Fragment>
                    {(data?.marketplaceId || !data?.listingType) && (userOffer.length === 0) &&
                        < PrimaryButton
                            heading="Make an offer"
                            primaryClassName="mt-15"
                            onClick={handleMakeAnOffer}
                        />
                    }
                </Fragment>
            }

            {(data?.vaccount !== loginUserInfo?.vaccount) && data.listingType !== 6 && data?.locked !== true &&
                <Fragment>
                    {(data?.marketplaceId || !data?.listingType) && (userOffer.length === 0) &&
                        < PrimaryButton
                            heading="Make an offer"
                            primaryClassName="mt-15"
                            onClick={handleMakeAnOffer}
                        />
                    }
                </Fragment>
            }


            {errorMessage?.state &&
                <p className="form-error mt-2">
                    {errorMessage?.message}
                </p>
            }
        </Fragment>
    )
}

export default AcceptOfferBlock
