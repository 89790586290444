import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import Paths from '../../helpers/Paths'
import { ArrowRightIcon } from '../../helpers/svgConst'
import offerImage from "../../images/CollectionImage/offerImage.png"
import GlanceImage from './GlanceImage'

const TierOffer = () => {

    const navigate = useNavigate()
    return (
        <Fragment>
            <div className='OfferSection mt-15'>
                <div className='offerImageBlock mx-auto'>
                    <GlanceImage src={offerImage} alt="Thumbnail" />
                </div>
                <div>
                    <p className='font14pxBold lightmode-text-flame'>Upgrade your Silver NFTs to Gold tier and get
                        extra benefits!</p>
                    <button
                        className='btn mt-10 lightmode-text-midnight-blue p-0 font12px d-flex align-items-center'
                        onClick={() => navigate(Paths.TierPage)}>Learn More <ArrowRightIcon className="ml-2" /></button>
                </div>
            </div>
        </Fragment>
    )
}

export default TierOffer