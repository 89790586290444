// All pages paths defined here, if you want to change path of any page then you can change the page variable value here
const Paths = {
    HomePage: "/",
    CollectionPage: "/collection/:collectionId",
    TokenDetailPage: "/card/detail/:cardId",
    ProfilePage: "/profile",
    LoginPage: "/login/:type",
    ListingPage: "/list-nft/card/:cardId",
    NFTDirectBuySuccessPage: "/nft-purchase-success",
    WalletPage: "/wallet",
    PaymentHistoryPage: "/payment-history",
    DepositMoneyPage: "/deposit-money",
    DirectBuyNFTPage: "/direct-buy-nft/:cardId",
    TierPage: "/tier/:tierType/:tierId",
    MarketplacePage: "/marketplace",
    WithdrawPage: "/withdraw-money",
    WithdrawForm: "/withdraw-form",
    WithdrawMoneySucessPage: "/withdraw-money-success",
    VerifyBankAccount: "/verify-bank-account",
    FinalVerifyOTP: "/final-verify-otp",
    ActivityHistoryPage: "/activity-history/:collectionId",
    OfferHistoryPage: "/offer-history/:cardId",
    TransactionHistoryPage: "/transaction-history/:collectionId",
    MoreCollectionsPage: "/view-all-collection",
    ConfirmBuyPage: "/card/buy/:cardId",
    ConfirmMakeAnOfferPage: "/card/make-an-offer/:cardId",
    KYCVerificationPage: "/kyc-verification/step/:kycStep",
    KYCCompletePage: "/kyc-complete",
    UserProfileSettings: "/setting",
    UnpackingCards: "/unpack/:packName/:collectionId/:assetId"
};

export default Paths

