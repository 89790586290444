import { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import Paths from "./helpers/Paths";
import Profile from "./pages/ProfilePage/Profile";
import ListYourNFT from "./pages/ListingPage/ListYourNFT";
import Wallet from "./pages/WalletPage/Wallet";
import PaymentHistory from "./pages/WalletPage/PaymentHistory";
import DepositMoney from "./pages/WalletPage/DepositMoney";
import NFTDirectBuySuccess from "./pages/DirectBuyNFT/NFTDirectBuySuccess";
import DirectBuyNFT from "./pages/DirectBuyNFT/DirectBuyNFT";
import WithdrawMoney from "./pages/WithdrawPage/Withdraw";
import WithdrawMoneySucess from "./pages/WithdrawPage/WithdrawMoneySucess";
import VerifyBankAccount from "./pages/WithdrawPage/VerifyBankAccount";
import FinalVerifyOTP from "./pages/WithdrawPage/FinalVerifyOTP";
import BuyAndMakeOffer from "./pages/BuyAndMakeOffer/BuyAndMakeOffer";
import KYCVerification from "./pages/KYCVerificationPage/KYCVerification";
import KYCComplete from "./pages/KYCVerificationPage/KYCComplete";
import PaypalTransactionFlow from "./pages/WalletPage/PaypalDepositMoney";
import UnpackCards from "./pages/UnpackCards/Unpacking";
import WithDrawForm from "./pages/WithdrawPage/withdrawForm";

// all private routes or logged in user routes defined here

const PrivateRoutes = () => {
    return (
        <Fragment>
            <Routes>

                <Route path={Paths.PaypalDeposit} element={<PaypalTransactionFlow />} />
                <Route path={Paths.NFTDirectBuySuccessPage} element={<NFTDirectBuySuccess />} />
                <Route path={Paths.DirectBuyNFTPage} element={<DirectBuyNFT />} />
                <Route path={Paths.ConfirmMakeAnOfferPage} element={<BuyAndMakeOffer />} />
                <Route path={Paths.KYCVerificationPage} element={<KYCVerification />} />
                <Route path={Paths.KYCCompletePage} element={<KYCComplete />} />
                <Route path={Paths.ListingPage} element={<ListYourNFT />} />
                <Route path={Paths.WalletPage} element={<Wallet />} />
                <Route path={Paths.PaymentHistoryPage} element={<PaymentHistory />} />
                {/* <Route path={Paths.DepositMoneyPage} element={<DepositMoney />} /> */}
                <Route path={Paths.DepositMoneyPage} element={<PaypalTransactionFlow />} />
                <Route path={Paths.WithdrawPage} element={<WithdrawMoney />} />
                <Route path={Paths.WithdrawForm} element={<WithDrawForm />} />
                <Route path={Paths.WithdrawMoneySucessPage} element={<WithdrawMoneySucess />} />
                <Route path={Paths.VerifyBankAccount} element={<VerifyBankAccount />} />
                <Route path={Paths.FinalVerifyOTP} element={<FinalVerifyOTP />} />
                <Route path={Paths.ProfilePage} element={<Profile />} />
                <Route path={Paths.UnpackingCards} element={<UnpackCards />} />
            </Routes>
        </Fragment>
    );
}
export default PrivateRoutes;
